
import { Options, Vue } from "vue-class-component";
import BoxWithNews from "@/components/PC/BoxWithNews.vue";
import { kMobileNewsLatest } from "@/config/newsData";

@Options({
  components: {
    BoxWithNews,
  },
  data() {
    return {
      list: kMobileNewsLatest.latest.news,
    };
  },
})
export default class Latest extends Vue {}
