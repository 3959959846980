import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e09138f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page_container_with_720" }
const _hoisted_2 = { class: "page_container_with_padding_row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BoxWithNews = _resolveComponent("BoxWithNews")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
        return (_openBlock(), _createBlock(_component_BoxWithNews, {
          key: `news_${index}`,
          item: item
        }, null, 8, ["item"]))
      }), 128))
    ])
  ]))
}